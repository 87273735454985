const Badge = ({ value, size = 'md', className = '' }) => {
  if (!value) {
    return null;
  }
  let sizes = 'text-xs leading-4';
  if (size === 'sm') {
    sizes = 'text-2xs leading-4';
  } else if (size === 'lg') {
    sizes = 'text-base leading-6';
  } else if (size === 'xl') {
    sizes = 'text-lg leading-6';
  }
  return (
    <span className={`font-pixel uppercase ${sizes} ${className}`}>
      {value}
    </span>
  );
};

export default Badge;
